<template>
  <v-container>
    <v-card
      flat
      height="600"
      class="d-flex justify-center align-center"
      v-if="loading"
    >
      <v-progress-circular
        pt-16
        color="#F1B24A"
        indeterminate
        width="8"
        size="70"
      >
      </v-progress-circular>
    </v-card>

    <v-row v-else>
      <v-col cols="12">
        <v-card
          v-for="(link, i) in linkCards"
          :key="i"
          flat
          outlined
          class="my-5"
          min-height="200"
          style="
            display: flex;
            flex-direction: column;
            align-items: left;
            border-radius: 10px;
          "
        >
          <v-row>
            <v-col md="9">
              <h3 class="ma-5 py-0" style="text-align: left">
                {{ link.title }}
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-autocomplete
                color="#000000"
                item-color="#4A697D"
                class="ma-5 py0 selection"
                chips
                deletable-chips
                depressed
                solo
                :label="'Search ' + link.text"
                :placeholder="'Search ' + link.text"
                v-on:change="SelectionChange($event, link.text)"
                v-model="link.default"
                :items="link.option"
                item-text="title"
                item-value="id"
                return-object
                multiple
                clearable
                :search-input.sync="searchInput"
                @change="searchInput = ''"
              >
                <template v-slot:item="data">
                  <div class="custom-item d-flex align-center">
                    <v-checkbox
                      color="cyan"
                      v-model="data.item.selected"
                      :label="data.item.title"
                    >
                    </v-checkbox>
                    <v-chip
                      class="ml-3"
                      style="background: #b0e4d7"
                      v-if="data.item.selected_in_other"
                    >
                      {{ data.item.selected_in_name }}
                    </v-chip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      linkCards: [],
      products: [],
      collections: [],
      loading: true,
    };
  },
  created() {
    console.log(JSON.parse(localStorage.getItem("chartdata")));
    console.log("after coming");
    this.chartdata = JSON.parse(localStorage.getItem("chartdata"));
    this.loadshopdata();
  },
  mounted() {
    this.fn = () => {
      this.save_chart_data_assoc();
    };
    this.$root.$on("save_chart_data_assoc", this.fn);
  },
  beforeDestroy() {
    this.$root.$off("save_chart_data_assoc", this.fn);
  },
  methods: {
    async loadshopdata() {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/shopify/shopall", {
          params: {
            shop: localStorage.getItem("shop"),
            user_chart_id: this.chartdata.id,
            chart_id: this.chartdata.chart_id,
          },
        })
        .then((response) => (this.data = response.data))
        .catch((error) => console.log(error));
      // console.log(this.data);
      this.loading = false;
      this.linkCards = [
        {
          title: " Link size chart to products:",
          text: "products",
          icon: "mdi-plus-circle",
          option: this.data.products,
          default: this.data.assigned_product,
        },
        {
          title: "Link size chart to collections:",
          text: "collections",
          icon: "mdi-plus-circle",
          option: this.data.collections,
          default: this.data.assigned_collection,
        },
      ];
      this.products = this.data.assigned_product;
      this.collections = this.data.assigned_collection;
      /* [0].option = this.data.products;
      this.linkCards[1].option = this.data.collections; */
      console.log(this.linkCards);
    },
    SelectionChange(option, type) {
      if (type == "products") {
        this.products = option;
      } else if (type == "collections") {
        this.collections = option;
      }

      console.log(this.products);

      /* this.collections= [];
      option.map((item)=>{
        if(item.type == "product"){
          this.products.push(item);
        }else if(item.type == "Collection"){
          this.collections.push(item);
        }
      }); */
    },
    save_chart_data_assoc() {
      console.log(this.chartdata);
      this.chartdata.assoclist = {
        products: this.products,
        collections: this.collections,
      };
      localStorage.setItem("chartdata", JSON.stringify(this.chartdata));
      if (typeof this.chartdata?.id == "number") {
        console.log(typeof this.chartdata?.id);
        this.$http
          .post(process.env.VUE_APP_SERVER_URL + "/template/upsert", {
            shop: localStorage.getItem("shop"),
            chart_data: this.chartdata,
          })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error));
      }
    },
    removeProduct(product, remove) {
      if (remove) {
        this.products = this.products.filter((p) => p.id !== product.id);
        return;
      } else {
        this.collections = this.collections.filter((c) => c.id !== product.id);
      }

      // this.linkCards = this.linkCards.map((card) => {
      //   if (card.text == "products") {
      //     card.option.map((p) => {
      //       if (p.id == product.id) {
      //         console.log(p.selected);
      //         return p;
      //       }
      //       return p;
      //     });
      //   }
      //   // console.log(card.title);
      //   return card;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-product {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: "Montserrat", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
}
.products {
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  } /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  } /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    background: #4d5c75;
    border: 1px solid #4a697d;
    box-sizing: border-box;
    border-radius: 21px;
  } /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #788397;
  }
}
.product {
  padding: 15px;
  max-width: 90%;
  margin: 10px auto;
  background: #ffffff;
  border: 1px solid #4a697d;
  box-sizing: border-box;
  border-radius: 21px !important;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .title {
    margin-left: 10px;
    font-family: "Montserrat", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 190px;
    text-align: left;
  }
  .product-img {
    width: 44px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
