<template>
  <div class="background">
    <v-container fluid>
      <LinksHeader></LinksHeader>
      <LinkCard></LinkCard>
      <SmartSizeFooter></SmartSizeFooter>
    </v-container>
  </div>
</template>

<script>
import LinkCard from "@/components/LinkProducts/LinkCard.vue";
import LinksHeader from "@/components/LinkProducts/LinksHeader.vue";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    LinkCard,
    LinksHeader,
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>