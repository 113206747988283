<template>
  <v-container>
    <v-row class="mt-10">
      <v-col md="10"> <h1 style="text-align: left">Link to Products</h1></v-col
      ><v-col md="2">
        <v-btn
          :disabled="disabled"
          @click.stop="goEditor"
          class="mx-2"
          width="180px"
          height="45px"
          depressed
          dark
        >
          <div class="font-weight-black">Save</div>
        </v-btn></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    goEditor() {
      this.disabled = true;
      this.$root.$emit("save_chart_data_assoc");
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>